const CITY_POINTS = [
  {
    "x": 441.515625,
    "y": 429.5,
    "label": "Москва",
    "labelEn": "Moscow",
    "size": 22,
    "region": "",
    "showLabel": true,
    "labelOffset": 0.7
  },
  {
    "x": 463.59375,
    "y": 495.53125,
    "label": "Каменск-Шахтинский",
    "size": 8,
    "region": "",
    "showLabel": true,
    "labelOffset": -1.2,
    "labelOffsetX": 1.2,
  },
  {
    "x": 459.359375,
    "y": 504.65625,
    "label": "Ростов-на-Дону",
    "size": 8,
    "region": "",
    "showLabel": true,
    "labelOffset": 0,
    "labelOffsetX": 1,
  },
  {
    "x": 473.59375,
    "y": 502.4375,
    "label": "Волгодонск",
    "size": 8,
    "region": "",
    "showLabel": true,
    "labelOffset": -1.2
  },
  {
    "x": 455.234375,
    "y": 520.71875,
    "label": "Краснодар",
    "labelEn": "Krasnodar",
    "size": 22,
    "region": "",
    "showLabel": true,
    "labelOffset": -1,
    "labelOffsetX": -0.2,
  },
  {
    "x": 469.5,
    "y": 522.5,
    "label": "Армавир",
    "size": 8,
    "region": "",
    "showLabel": true,
    "labelOffset": -1.1,
    "labelOffsetX": 1.6,
  },
  // {
  //   "x": 464.671875,
  //   "y": 528.21875,
  //   "label": "Майкоп",
  //   "size": 8,
  //   "region": "",
  //   "showLabel": true,
  //   "labelOffset": -0.2,
  //   "labelOffsetX": 1.85,
  // },
  {
    "x": 445.328125,
    "y": 525.296875,
    "label": "Новороссийск",
    "size": 8,
    "region": "",
    "showLabel": true,
    "labelOffset": 0,
    "labelOffsetX": 2.1,
  },
  {
    "x": 459.546875,
    "y": 534.109375,
    "label": "Сочи",
    "size": 8,
    "region": "",
    "showLabel": true,
    "labelOffset": -0.6,
    "labelOffsetX": 2,
  },
  {
    "x": 482.359375,
    "y": 530.59375,
    "label": "Пятигорск",
    "size": 8,
    "region": "",
    "showLabel": true,
    "labelOffset": 0,
    "labelOffsetX": 1,
  },
  {
    "x": 400.328125,
    "y": 529.296875,
    "label": "Симферополь",
    "size": 8,
    "region": "",
    "showLabel": true,
    "labelOffset": -1.4
  },
];

export default CITY_POINTS;
