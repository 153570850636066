const CITY_CONTACTS = [
  {
    city: "Москва",
    address: "107031, г. Москва, ул. Кузнецкий Мост, дом 21/5, этаж 5, помещение V, комната 30",
    addressHref: "https://yandex.ru/maps/-/CCUA6WdjhA",
    email: "info@logistic-yug.ru",
    phone: "7 495 62 77 912",
  },
  {
    city: "Армавир",
    address: "г. Армавир",
    addressHref: "",
    email: "info@logistic-yug.ru",
    phone: "7 495 62 77 912 ",
  },
  {
    city: "Волгодонск",
    address: "г. Волгодонск",
    addressHref: "",
    email: "info@logistic-yug.ru",
    phone: "7 495 62 77 912 ",
  },
  {
    city: "Каменск-Шахтинский",
    address: "г. Каменск-Шахтинский",
    addressHref: "",
    email: "info@logistic-yug.ru",
    phone: "7 495 62 77 912 ",
  },
  {
    city: "Краснодар",
    address: "г. Краснодар, ул. Дзержинского, д. 40",
    addressHref: "https://yandex.ru/maps/-/CCUEEWeDdD",
    email: "info@logistic-yug.ru",
    phone: "7 495 62 77 912 ",
  },
  {
    city: "Новороссийск",
    address: "г. Новороссийск",
    addressHref: "",
    email: "info@logistic-yug.ru",
    phone: "7 495 62 77 912",
  },
  {
    city: "Пятигорск",
    address: "г. Пятигорск",
    addressHref: "",
    email: "info@logistic-yug.ru",
    phone: "7 495 62 77 912",
  },
  {
    city: "Ростов-на-Дону",
    address: "г. Ростов-на-Дону",
    addressHref: "",
    email: "info@logistic-yug.ru",
    phone: "7 495 62 77 912",
  },
  {
    city: "Сочи",
    address: "г. Сочи",
    addressHref: "",
    email: "info@logistic-yug.ru",
    phone: "7 495 62 77 912",
  },
  {
    city: "Симферополь",
    address: "г. Симферополь, п. Грэсовский, ул. Монтажная 12",
    addressHref: "https://yandex.ru/maps/-/CCUEEWRo9D",
    email: "kalyuta.dv@logistic-yug.ru",
    phone: "7 988 460 66 47",
  },
];

export default CITY_CONTACTS;
